import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import { routesWhiteList } from './setting.config'
import { TabsBarStorage } from '@/utils/storage'

NProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const accessToken = store.getters['user/accessToken']
  if (accessToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      return
    }

    // 是否已经存在路由
    const routes = store.getters['routes/routes']
    if (routes?.length || to.path === '/401') {
      next()
      return
    }

    // 初始化路由
    store.dispatch('routes/initRoutes')
    // 初始化用户信息
    await store.dispatch('user/getUserInfo')
    // 初始化 TabsBar
    const tabRoutes = TabsBarStorage.get()?.map((path) => router.match(path))
    if (tabRoutes) store.dispatch('tabsBar/initTab', tabRoutes)
    if (to.path !== '/applications') {
      store.dispatch('tabsBar/setTab', router.match('/index'))
    }
    next({ ...to, replace: true })

    return
  }

  // 判断当前路由是否在白名单中
  if (routesWhiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    // 无 accessToken , 进入登录页
    next({ path: '/login', query: { redirect: to.path } })
  }
})

router.beforeResolve((to, from, next) => {
  next()
  document.title = to?.meta?.title || process.env.VUE_APP_NAME
  NProgress.done()
})
