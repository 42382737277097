import Mousetrap from 'mousetrap'
import { GlobalEventType } from '@/constants'

/**
 * 修饰符只支持 ctrl,shift,alt,meta
 * MacOS 可用 option 替代 alt, command 替代 meta
 * 特殊键: backspace, tab, enter, return, capslock, esc, escape, space, pageup, pagedown, end, home, left, up, right, down, ins, del, plus
 */
export const KeyboardActions = [
  {
    label: '仅触发当前活动页【提交】按钮',
    value: 'ctrl+enter',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.SUBMIT)
      return false
    },
  },
  {
    label: '触发当前活动页【保存】按钮',
    value: 'ctrl+s',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.SAVE)
      return false
    },
  },
  {
    label: '切换标签页',
    value: 'tab',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.SWITCH_TAB)
      return false
    },
  },
  {
    label: '触发当前活动页【打印】事件',
    value: 'ctrl+p',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.PRINT)
      return false
    },
  },
  {
    label: '触发当前活动页【ESC】事件',
    value: 'esc',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.ESC)
      return false
    },
  },
  {
    label: '触发当前活动页【F8】事件',
    value: 'f8',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.FAST_ADD)
      return false
    },
  },
]

const install = (Vue) => {
  KeyboardActions.forEach((item) => {
    Mousetrap.bind(item.value, item.handler.bind(Vue.prototype))
  })
}
export default install
