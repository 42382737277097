import { convertRouter, addButtonRouter } from '@/utils/handleRoutes'
import { constantRoutes, resetRouter } from '@/router'
import { cloneDeep } from 'lodash-es'
const state = () => ({
  routes: [],
  subRoutes: [],
})
const getters = {
  routes: (state) => state.routes,
  subRoutes: (state) => state.subRoutes,
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes)
  },
  setSubRoutes(state, subRoutes) {
    state.subRoutes = subRoutes
  },
  clear(state) {
    state.routes = []
    state.subRoutes = []
  },
}
const actions = {
  // 初始化路由
  initRoutes({ commit }) {
    commit('setRoutes', [])
  },
  // 设置所有路由
  setRoutes({ commit }, permissions = []) {
    // 由于设置的严苛模式，因此需要对该对象进行深拷贝
    const pageRoutes = convertRouter(cloneDeep(permissions))
    const buttonRoutes = addButtonRouter(pageRoutes)
    const routes = pageRoutes.concat(buttonRoutes)
    commit('setRoutes', routes)
    return routes
  },
  // 设置某个路由下的子路由，比如菜单hover显示的时候
  setSubRoutes({ commit }, subRoutes) {
    commit('setSubRoutes', subRoutes)
  },
  clear({ commit }) {
    commit('clear')
    resetRouter()
  },
}
export default { state, getters, mutations, actions }
