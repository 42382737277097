<template>
  <el-aside class="layout-aside select-none" :class="{ 'is-collapse': isCollapse }">
    <div
      class="flex side-bar"
      :class="{ 'min-h-full': isCollapse }"
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseOut"
    >
      <ul class="collapse-side-menu">
        <template v-for="(menu, index) in routes">
          <li
            v-if="!isHidden(menu)"
            :key="menu.path"
            :data-index="index"
            @click="handleMainMenuClick(menu)"
            :class="{ 'is-active': [hoverPath, activePath].includes(menu.path) }"
          >
            <svg class="menu-icon" aria-hidden="true" style="width: 20px; height: 20px">
              <use :xlink:href="`#${menuIcon(menu) || 'mg-icon_shouye_normal'}`"></use>
            </svg>
            <i class="iconfont" :class="menuIcon(menu) || 'mg-icon_shouye_normal'" />
            <span>{{ menuTitle(menu) }}</span>
          </li>
        </template>
        <!-- 占位标签，请勿删除 -->
        <li>
          <span>&nbsp;</span>
        </li>
      </ul>
      <div class="side-submenu" v-show="isCollapse">
        <Menu :data="subRoutes" />
      </div>
    </div>
    <div class="collapse-icon" @click="handleCollapseMenu">
      <i class="iconfont mg-icon_open" />
    </div>
  </el-aside>
</template>

<script>
  import Menu from '../Menu'
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Aside',
    components: { Menu },
    data() {
      return {
        isCollapse: false,
        activePath: '',
        hoverPath: '',
      }
    },
    computed: {
      ...mapGetters({
        routes: 'routes/routes',
        subRoutes: 'routes/subRoutes',
      }),
    },
    watch: {
      $route: {
        handler(route) {
          this.activePath = route?.redirectedFrom || route.matched[0].path
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions({
        setSubRoutes: 'routes/setSubRoutes',
      }),
      isHidden(menu) {
        return menu?.meta?.hidden
      },
      menuTitle(menu) {
        return menu?.meta?.title
      },
      menuIcon(menu) {
        return menu?.meta?.icon
      },
      handleCollapseMenu() {
        this.isCollapse = !this.isCollapse
      },
      handleMainMenuClick(menu) {
        this.activePath = menu.path
      },
      handleMouseOut() {
        this.hoverPath = ''
        const route = this.routes.find((route) => route.path === this.activePath)
        this.setSubRoutes(route?.children || [])
      },
      handleMouseOver(e) {
        if (e.target instanceof HTMLLIElement) {
          const index = e.target.getAttribute('data-index')
          if (index !== null) {
            const route = this.routes[index]
            if (route && this.hoverPath !== route.path) {
              this.hoverPath = route.path
              this.setSubRoutes(route.children)
            }
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .layout-aside {
    width: $mg-menu-collapse-width !important;
    transition: width 200ms;
    height: inherit;
    box-shadow: 2px 0px 6px rgb(0 21 41 / 35%);
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 11;
    &::-webkit-scrollbar {
      width: 0;
    }
    .collapse-side-menu {
      width: $mg-menu-collapse-width;
      display: flex;
      flex-direction: column;
      li {
        height: 54px;
        display: flex;
        line-height: 54px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:not(&:last-child) {
          &.is-active,
          &:hover {
            cursor: pointer;
            background-color: $mg--color-primary;
            color: #fff;
            svg {
              display: none;
            }
            i {
              font-size: 20px;
              display: block;
            }
          }
        }
        i {
          line-height: 1;
          display: none;
        }
        span {
          line-height: 1;
        }
      }
    }
    .side-submenu {
      overflow-x: hidden;
      background-color: #f1f1f1;
      width: calc(100% - $mg-menu-collapse-width);
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    &.is-collapse {
      width: $mg-side-menu-width !important;
    }
    .collapse-icon {
      position: fixed;
      bottom: 0;
      width: $mg-menu-collapse-width;
      text-align: center;
      height: 54px;
      line-height: 54px;
      border-top: 1px solid #f1f1f1;
      background: #fff;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
        color: $mg--color-primary;
      }
      i {
        font-size: 20px;
      }
    }
  }
  // 非展开状态下
  .layout-aside:not(.is-collapse) {
    .side-bar:hover {
      .side-submenu {
        position: fixed;
        display: block !important;
        width: calc($mg-side-menu-width - $mg-menu-collapse-width);
        top: $mg-layout-header-height;
        left: $mg-menu-collapse-width;
        bottom: 0;
        box-shadow: 2px 0px 6px rgb(0 21 41 / 35%);
      }
    }
  }
</style>
