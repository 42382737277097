export function convertRouter(asyncRoutes) {
  return asyncRoutes.map((route) => {
    // component setting
    if (route.component) {
      // first
      if (route.component === 'Layout') {
        route.component = (resolve) => require(['@/layout'], resolve)
        // seconed
      } else if (route.component === 'EmptyLayout') {
        route.component = (resolve) => require(['@/layout/EmptyLayout'], resolve)
      } else {
        const path = `views${route.component}`
        route.component = (resolve) => require([`@/${path}`], resolve)
      }
    } else {
      route.component = (resolve) => require(['@/layout/EmptyLayout'], resolve)
    }

    // iframe 页面 component 设置为空
    if (route.componentName?.startsWith('iframe-')) {
      route.component = null
    }

    // route recursion
    if (route.children && route.children.length) {
      route.children = convertRouter(route.children)
    }

    // delete [] route
    if (route.children && route.children.length === 0) {
      delete route.children
    }
    // return route
    return {
      name: route.componentName || route.menuId,
      path: route.path,
      component: route.component,
      children: route.children || [],
      menuId: route.menuId,
      meta: {
        title: route.menuName,
        icon: route.icon,
        extLink: route.extLink,
        docLink: route.docLink,
        buttons: route.buttons,
        hidden: route.hidden || false,
      },
    }
  })
}

/**
 * 蒋按钮跳转的地址，添加到路由中
 * @param {*} convertRoutes
 */
export function addButtonRouter(convertRoutes) {
  return convertRoutes.reduce((buttonRoutes, route) => {
    if (route?.children?.length) {
      return buttonRoutes.concat(addButtonRouter(route.children))
    } else if (route?.meta?.buttons?.length) {
      const EmptyRoute = {
        path: '/' + route.name,
        component: (resolve) => require(['@/layout'], resolve),
        children: [],
        meta: {
          hidden: true,
        },
      }
      route.meta.buttons.forEach((button) => {
        if (button.component && button.componentPath) {
          EmptyRoute.children.push({
            path: button.componentPath,
            name: button.componentName,
            component: (resolve) => require([`@/views${button.component}`], resolve),
            meta: {
              name: button.componentName,
              title: button.menuName + route.meta.title,
              buttons: route.meta.buttons,
              noKeepAlive: false,
              hidden: true,
            },
          })
        }
      })

      if (EmptyRoute.children.length) {
        buttonRoutes.push(EmptyRoute)
      }
    }
    return buttonRoutes
  }, [])
}
