import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store'
import { maxTabs } from '@/config'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'applications',
    children: [
      {
        path: '/applications',
        name: 'Applications',
        component: () => import('@/views/Applications.vue'),
        meta: { title: '我的应用', affix: true },
      },
    ],
    meta: { title: '我的应用' },
  },

  {
    path: '/information',
    component: Layout,
    children: [
      {
        path: '/information',
        name: 'Information',
        component: () => import('@/views/Information.vue'),
        meta: { title: '基本资料' },
      },
    ],
    meta: { title: '基本资料' },
  },

  {
    path: '/security',
    component: Layout,
    children: [
      {
        path: '/security/:operate?',
        name: 'Security',
        component: () => import('@/views/Security.vue'),
        meta: { title: '账号安全' },
      },
    ],
    meta: { title: '账号安全' },
  },

  {
    path: '/records',
    component: Layout,
    children: [
      {
        path: '/records',
        name: 'Records',
        component: () => import('@/views/Records.vue'),
        meta: { title: '登录记录' },
      },
    ],
    meta: { title: '登录记录' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: '登录', hidden: true },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
    meta: { title: '401', hidden: true },
  },
  {
    path: '*',
    redirect: '404',
    meta: { title: 'not Found', hidden: true },
  },
]

// 开发环境下，添加一些本地用于模拟测试的路由
// if (process.env.NODE_ENV === 'development') {
//   const mockRoutes = require('../../test/routes.js').default
//   constantRoutes.push(...mockRoutes)
// }

function createRouter() {
  return new VueRouter({
    routes: constantRoutes,
  })
}
const router = createRouter()

export function resetRouter() {
  router.matcher = createRouter().matcher
}

const originalPush = router.push
router.push = function (...args) {
  const location = this.match(args[0])
  const visiteRoutes = store.getters['tabsBar/visiteRoutes']
  const target = visiteRoutes.find((item) => item.path === location.path)
  if (!target && visiteRoutes?.length > maxTabs) {
    const message = `最多只能打开${maxTabs + 1}个页面，请先关闭部分页面。是否关闭前五个页面？`
    Vue.prototype
      .$mgAlert(message, '提示', { confirmButtonText: '关闭并打开新页面' })
      .then(() => {
        const changePages = store.getters['tabsBar/changePages']
        if (visiteRoutes.some((item) => changePages.includes(item?.name))) {
          return Vue.prototype.$mgConfirm('当前页面正在编辑，确认关闭?')
        }
      })
      .then(() => {
        visiteRoutes.slice(1, 5).forEach((route) => store.dispatch('tabsBar/removeTab', route))
        originalPush.call(this, ...args)?.catch(() => {})
      })
    return
  }
  return originalPush.call(this, ...args)?.catch(() => {})
}

export default router
