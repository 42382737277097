module.exports = {
  projectName: process.env.VUE_APP_NAME,
  tokenName: 'X-TOKEN',
  // 最多可显示的标签个数
  maxTabs: 9,
  // 路由白名单
  routesWhiteList: ['/login', '/401', '/404'],
  // 监听页面内容改变
  listeningPageChangeRouteNames: ['apple'],
}
