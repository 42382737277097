import request from '@/utils/request'
import { tokenName } from '@/config'

export function login(data) {
  return request({
    url: '/sso-api/SsoUserAcc/doLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken) {
  return request({
    url: '/sso-api/SsoUserAcc/getLoginInfo',
    method: 'get',
    data: {
      [tokenName]: accessToken,
    },
  })
}

export function logout() {
  return request({
    url: '/sso-api/SsoUserAcc/doExit',
    method: 'get',
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}

// 修改密码
export function putChangePassWord(data) {
  return request({
    url: '/sso-api/userAccount/changePassWord',
    method: 'put',
    data: data,
  })
}

//校验图形验证码
export function reqCheck(data) {
  return request({
    url: '/sso-api/captcha/check',
    method: 'post',
    data: data,
  })
}
//获取图形验证码
export function reqGet(data) {
  return request({
    url: '/sso-api/captcha/get',
    method: 'post',
    data: data,
  })
}