/**
 * http 状态码
 */
export enum HttpStatusCode {
  /**
   * 无权限
   */
  UNAUTHORIZED = 401,
  /**
   * 登录失效
   */
  PAYMENT_REQUIRED = 402,
  /**
   * 404
   */
  NOT_FOUND = 404,
  /**
   * 处接口返回200外的其他情况
   */
  NO_SUCCESS = 100,
  /**
   * 接口正常返回数据
   */
  SUCCESS = 200,
  /**
   * 从缓存返回数据
   */
  CACHE_SUCCESS = 600
 
}

export enum GlobalEventType {
  /**
   * 打开快捷键
   */
  KEYBOARD_SHORTCUTS = 'KEYBOARD_SHORTCUTS',
  /**
   * 保存表单
   */
  SAVE = 'SAVE',
  /**
   * 提交表单
   */
  SUBMIT = 'SUBMIT',
  /**
   * 打印
   */
  PRINT = 'PRINT',
  /**
   * 切换标签页
   */
  SWITCH_TAB = 'SWITCH_TAB',
  /**
   * 关闭当前页
   */
  ESC = 'ESC',
  /**
   * 回车
   */
  ENTER = 'ENTER',
  /**
   * 打开快速添加
   */
  FAST_ADD = 'FAST_ADD'
}
