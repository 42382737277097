import { TabsBarStorage, ChangePwdStorage, TokenStorage, WebFunnyStorage } from '@/utils/storage'
import * as UserApi from '@/api/user'

const state = () => ({
  accessToken: TokenStorage.get(),
  belongId: null,
  employeeName: null,
  employeeNo: null,
  permissions: [],

  // sso user info
  userId: null,
  tokenInfo: '',
  user: {},
})
const getters = {
  accessToken: (state) => state.accessToken,
  belongId: (state) => state.belongId,
  permissions: (state) => state.permissions,
  employeeName: (state) => state.employeeName,
  employeeNo: (state) => state.employeeNo,
  userId: (state) => state.userId,
  tokenInfo: (state) => state.tokenInfo,
  user: (state) => state.user,
}
const mutations = {
  removeAccessToken(state) {
    state.accessToken = ''
    TokenStorage.clear()
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    TokenStorage.set(accessToken)
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setBelongId(state, belongId) {
    state.belongId = belongId
  },
  setEmployeeNo(state, employeeNo) {
    state.employeeNo = employeeNo
  },
  setEmployeeName(state, employeeName) {
    state.employeeName = employeeName
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setTokenInfo(state, tokenInfo) {
    state.tokenInfo = tokenInfo
  },
  setUser(state, user) {
    state.user = user
  },
  clearUserData(state) {
    state.belongId = ''
    state.employeeName = ''
    state.employeeNo = ''
    state.permissions = []
    state.userId = null
    state.tokenInfo = ''
    state.user = {}
  },
}
const actions = {
  removeAccessToken({ commit }) {
    commit('removeAccessToken')
    commit('clearUserData')
    TabsBarStorage.clear()
  },
  async login({ commit }, loginInfo) {
    const { data } = await UserApi.login({
      ...loginInfo,
    })
    if (data.tokenInfo) {
      let tokenInfo = JSON.parse(data.tokenInfo)
      if (tokenInfo?.isLogin) {
        commit('setAccessToken', tokenInfo.tokenName + ',' + tokenInfo.tokenValue)
        // TODO 是否需要重置密码
        ChangePwdStorage.set(false)
      }
    }
  },
  async getUserInfo({ state, commit }) {
    const { data } = await UserApi.getUserInfo(state.accessToken)

    // 写入 webfunny userId
    if (process.env.VUE_APP_WEB_MONITOR_ID) {
      WebFunnyStorage.set({
        userId: data.employeeId,
        userTag: data.belongId,
        projectVersion: '1.0.0',
      })
    }
    // TODO 待完善
    commit('setUserId', data.userId)
    commit('setTokenInfo', data.tokenInfo)
    commit('setUser', data.user)
  },
  // 修改密码
  async changePassword({ commit }, changePwdInfo) {
    await UserApi.putChangePassWord(changePwdInfo)
    commit('removeAccessToken')
    ChangePwdStorage.clear()
  },
  // 退出登录清空 accessToken 和路由
  async logout({ dispatch }) {
    await UserApi.logout()
    dispatch('removeAccessToken')
    dispatch('routes/clear', {}, { root: true })
    dispatch('tabsBar/clear', {}, { root: true })
  },
}
export default { state, getters, mutations, actions }
