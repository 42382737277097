<template>
  <div class="tabsbar select-none">
    <div class="placeholder-block"></div>
    <el-tabs class="tabs" type="card" @tab-remove="handleRemoveTab" v-model="tabActive" @tab-click="handleTabClick">
      <el-tab-pane
        v-for="route in visiteRoutes"
        :key="route.path"
        :label="route.meta.title"
        :name="route.path"
        :closable="!isAffix(route)"
      />
    </el-tabs>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'TabsBar',
    data() {
      return {
        tabActive: null,
      }
    },
    watch: {
      $route: {
        handler(route) {
          if (this.tabActive !== route.path) {
            this.tabActive = route.path
            this.setTab(route)
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters({
        visiteRoutes: 'tabsBar/visiteRoutes',
        changePages: 'tabsBar/changePages',
      }),
    },
    methods: {
      ...mapActions({
        setTab: 'tabsBar/setTab',
        removeTab: 'tabsBar/removeTab',
      }),
      isAffix(tag) {
        return tag.meta?.affix
      },
      async handleRemoveTab(path) {
        const index = this.visiteRoutes.findIndex((item) => item.path === path)
        const delRoute = this.visiteRoutes[index]
        await this.$mgCloseEditPageConfirm(delRoute?.name)
        if (this.tabActive === path) {
          const currentRoute = this.visiteRoutes[index - 1] || this.visiteRoutes[index + 1]
          if (currentRoute) {
            if (this.tabActive !== currentRoute.path) {
              this.handleTabClick({ name: currentRoute.path })
            }
          }
        }
        this.removeTab(delRoute)
      },
      handleTabClick(tab) {
        if (this.$route.path !== tab.name) {
          const route = this.visiteRoutes.find((item) => item.path === tab.name)
          this.$router.push(route)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tabsbar {
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    border-bottom: 1px solid #ddd;
    .tabs {
      width: 100%;
      position: fixed;
      top: $mg-layout-header-height;
      z-index: 1;
    }
    .placeholder-block {
      height: $mg-layout-tabsbar-height;
    }
  }
</style>
<style lang="scss" scoped>
  :deep(.el-tabs__item.is-active) {
    background: $mg-base-hover-color;
  }
  :deep(.el-tabs__header) {
    margin: 0;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border: none;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
    border-bottom: none;
  }
</style>
