const { HttpStatusCode } = require('@/constants/index')
const salt = process.env.X_WEB_SECRET || 'ehdQILITTEK1PXs3PVVngkAE04haa1137g4HHSFgVaRQgU8kyZg9ew88WcAR93uM'
module.exports = {
  salt,
  // baseURL: 'http://192.168.10.155:8888/',
  //替换为sso后端地址
  baseURL: '/',
  /** 默认超时时间5分钟 */
  requestTimeout: 30000,
  /** 默认 application/json */
  contentType: 'application/json;charset=UTF-8',
  /** 接口成功返回的code码 */
  successCodes: [HttpStatusCode.SUCCESS, HttpStatusCode.CACHE_SUCCESS],
  /** 忽略接口错误提示的code码 */
  ignoreCodes: [4002630, 4002631],
  /** 需要重新登录的code码 */
  loginBackInCodes: [4011021, 4011155,401901],
  /** 缓存数据白名单 */
  cacheWhiteList: ['/mg/system/systemConfig'],
  /** 全局 loading 文本 */
  loadingText: '加急处理中...',
}
