import { getGlobalTableConfig, saveGlobalTableConfig } from '@/api/config'
import ElementUI, { Message, MessageBox } from 'element-ui'
import { VXETable, Table, Column, Footer, Keyboard, Edit, Tooltip } from 'vxe-table'
import XEUtils from 'xe-utils'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import MGUI from '@mg/mg-ui'
import MessageBoxContent from '@/components/message-box-content'
import EventBus from './event-bus'
import { getStorage, setStorage } from '@/utils/storage'

const install = (Vue) => {
  /** ElementUI **/
  Vue.use(ElementUI, { size: 'small' })
  /** VXETable */
  VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
  })
  Vue.use(Column).use(Footer).use(Keyboard).use(Edit).use(Tooltip).use(Table)
  /** MgUI **/
  Vue.use(MGUI, {
    table: {
      getColumnApi: getGlobalTableConfig,
      setColumnApi: saveGlobalTableConfig,
    },
    dropdownButton: {
      getStorage,
      setStorage(key, data) {
        setStorage(key, data.printTemplateId)
      },
    },
    // 通用本地缓存配置
    storage: {
      getStorage,
      setStorage,
    },
  })

  /* 全局消息弹框 */
  Vue.prototype.$mgMessage = function (message, type) {
    Message({
      offset: 60,
      showClose: true,
      message: message,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: 3000,
    })
  }

  /* 全局 mgConfirm */
  Vue.prototype.$mgConfirm = function (content, title, options) {
    const h = this.$createElement ? this.$createElement : new Vue({}).$createElement
    return MessageBox.confirm(content, '-', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true,
      type: 'warning',
      customClass: 'el-message-box__no-title',
      closeOnClickModal: false,
      message: h(MessageBoxContent, {
        props: {
          title,
          content,
        },
      }),
      ...options,
    })
  }

  /* 全局 mgAlert */
  Vue.prototype.$mgAlert = function (content, title, options) {
    return MessageBox.confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      ...options,
    })
  }

  /**
   * 编辑的页面确认关闭弹框
   * @param {String} routeName 路由名称
   */
  Vue.prototype.$mgCloseEditPageConfirm = function (routeName) {
    const changePages = this.$store.getters['tabsBar/changePages']
    if (changePages.includes(routeName)) {
      return this.$mgConfirm('当前页面正在编辑，确认关闭?')
    }
  }

  // dayjs 通过 ProvidePlugin 全局引入
  Vue.prototype.dayjs = dayjs
  // BN 通过 ProvidePlugin 全局引入
  Vue.prototype.BN = BN
  // 全局 EventBus
  Vue.prototype.$GlobalEventBus = EventBus
}

if (typeof window !== void 0 && window.Vue) {
  install(window.Vue)
}

export default install
