import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { successCodes, ignoreCodes, loginBackInCodes, cacheWhiteList } from '@/config'
import { AxiosError } from 'axios'
import { HttpStatusCode } from '@/constants'

/**
 * 关闭loading
 * @param {*} config
 */
function closeLoading(config) {
  if (config?.loading) {
    setTimeout(() => {
      if (config?.loadingInstance) {
        config.loadingInstance.close()
        config.loadingInstance = null
      }
    })
  }
}

/**
 * 状态码为 2xx 范围内都会触发此方法
 * @param {*} response
 * @returns
 */
export function handleResponseSuccess(response) {
  const { data, config } = response
  const { code } = data

  // 关闭 loading
  closeLoading(config)

  // 处理文件流
  if (config.responseType === 'blob') return data

  // 处理接口正常返回数据
  if (successCodes.includes(code)) {
    if (cacheWhiteList?.includes(config.url)) {
      LocalForage.setItem(config.url, data.data)
    }
    return data
  }
  // 处理其他异常情况，使用自定义错误码
  response.status = HttpStatusCode.NO_SUCCESS
  return handleResponseError({ response })
}
/**
 * 状态码超出 2xx 范围会触发此方法
 *  status http状态码
 *  code   接口返回的特定状态码
 */
export function handleResponseError({ code: cancelCode, message, response }) {
  if (!response) {
    response = { data: { code: cancelCode, msg: message } }
  }
  // 关闭 loading
  closeLoading(response.config || { loading: true })

  // 处理取消重复请求的错误
  if (cancelCode === AxiosError.ERR_CANCELED) {
    return Promise.reject({ code: cancelCode, msg: message })
  }
  // 处理客户端请求404错误
  let { status, data, statusText, config } = response
  if (status === HttpStatusCode.NOT_FOUND) {
    data = { code: status, msg: `${statusText}【 ${config.url} 】` }
  }
  const { code, msg } = data
  switch (status) {
    // 无权限/登录失效
    case HttpStatusCode.UNAUTHORIZED:
    case HttpStatusCode.PAYMENT_REQUIRED:
      Vue.prototype.$mgMessage(msg, 'error')
      router.push({ name: '401' })
      break
    // 处理 handleResponseSuccess 中状态码不在successCodes中的情况
    case HttpStatusCode.NO_SUCCESS:
      // 根据指定的code，忽略错误消息提醒
      if (ignoreCodes.includes(code)) return
      break
    default:
      Vue.prototype.$mgConfirm(msg, null, { showCancelButton: false, type: 'error' }).finally(() => {
        // 根据指定的code，提示用户重新登录
        if (loginBackInCodes.includes(code)) {
          store.dispatch('user/logout').then(() => {
            router.push({ name: 'login' })
          })
        }
      })
  }
  return Promise.reject(data)
}
